export default class Constants {

  /** FONTS */
  static FONT_BOLD = "font_bold";
  static FONT_REGULAR = "font_regular";

  /** LOYALTY */
  static MIN_LEVEL_NEEDED_TO_TRIGGER = 3;
  static MAX_AMOUNT_OF_LOYALTY_USES = 3;

  /** GENERAL */
  static MATCH_BALL_RESET_DURATION = 2500;

}
