import Setup from "@/helpers/Setup";
import Phaser, { Scale } from "phaser";
import { linearInterpolation } from '../../../configs/helpers';
import Scaling from "../../../configs/scaling";
import { IControlsPath } from "../../../controllers/controlController";
import eventsController from "../../../controllers/eventsController";
import { parseHexColor } from "@/game/shared/utils/Helpers"

export default class Ball extends Phaser.GameObjects.Sprite {
  declare body: Phaser.Physics.Arcade.Body;
  trail: Phaser.GameObjects.Graphics;
  trailColor: number;
  points: any[];
  head: any;
  particleEmitter: Phaser.GameObjects.Particles.ParticleEmitter;

  isStopped = false;
  speed: number = Scaling.getPixelbyDPR(550) * Scaling.GAME_BASE_DIFF_HEIGHT;
  physicsMoveTimers: any[] = [];
  startPosition: any;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, 'ball');
    this.scene.add.existing(this);

    /** Settings */
    this.trail = this.scene.add.graphics();
    this.trailColor = parseHexColor(Setup.getValue('settings.game.general.ballColor')).color
    this.points = [];
    this.head = { x: 0, y: 0 };
    this.startPosition = {
      x: this.x,
      y: this.y
    };

    /** General */
    this.setScale(Scaling.GAME_BASE_DIFF_HEIGHT);

    /** Physics */
    this.scene.physics.world.enable(this);
    this.body.setCircle(this.width / 2);
    this.body.collideWorldBounds = true;
    this.body.useDamping = true;
    this.body.setDrag(0.4, 0.4);
    this.body.setBounce(1, 1);
    this.body.setAngularDrag(100);

    /** Particle Emitter */
    const particle = this.scene.add.particles('emitter_score');
    this.particleEmitter = particle.createEmitter({
      speed: { min: -300, max: 300 },
      angle: { min: 0, max: 360 },
      scale: { start: 0.3, end: 0 },
      lifespan: 600,
      quantity: 30,
      frequency: -1
    });

    /** Events */
    eventsController.on('ball-explode', (data: any) => this.explode(data.x, data.y));
    eventsController.on('ball-shoot', (data: any) => this.shoot(data.path));
    eventsController.on('ball-reset', () => this.reset());
    eventsController.on('ball-stopped', () => this.handleHit());
  }

  shoot(points: IControlsPath[]) {
    this.isStopped = false;

    // Audio //
    // this.scene.audio_shoot.play();

    // Movement: calculate difference between user pointer and ball coords //
    let deltaSum = 0;
    const diff = {
      x: this.x - points[0].x,
      y: this.y - points[0].y
    };

    // Movement: if user movement is very long, cut the movement so that only 20 vectors remain //
    if (points.length > 40) {
      const vectorPartition = Math.floor(points.length / 20);
      const newPoints = [] as any[];
      points.forEach((point, index) => {
        if ((index + 1) % vectorPartition === 0) {
          newPoints.push(point);
        }
      })
      points = newPoints;
    }

    // Movement: for each user pointer coord, create a timer with a delay, after delay use physics to move to coords //
    points.forEach(point => {
      deltaSum += point.delta;
      const timer = this.scene.time.delayedCall(deltaSum * 1.1, () => {
        // Movement: cancel movement if the ball is stopped by enemy team //
        if (!this.isStopped) {
          const forceX = diff.x < 0 ? point.x - Math.abs(diff.x) : point.x + Math.abs(diff.x);
          const forceY = diff.y < 0 ? point.y - Math.abs(diff.y) : point.y + Math.abs(diff.y);
          this.scene.physics.moveTo(this, forceX, forceY, this.speed);
        }
      })

      // Movement: push the timer into collection, for easy garbage collection later on //
      this.physicsMoveTimers.push(timer);
    })

    this.body.setAngularVelocity(500);
  }

  explode(x: number, y: number) {
    this.particleEmitter.setPosition(x, y);
    this.particleEmitter.explode(30, x, y);
    this.hide();
  }

  hide() {
    this.setAlpha(0);
    this.trail.setAlpha(0);

    this.resetBody();
  }

  reset() {
    // Trail: remove all saved positions //
    this.points = [];

    // Movement: clean and garbage collect all timers //
    this.physicsMoveTimers.forEach(timer => {
      this.scene.time.removeEvent(timer);
    })
    this.physicsMoveTimers = [];

    // Ball: Reset position and physics //
    this.resetBody();

    // Ball: Un-hide  //
    this.setAlpha(1);
    this.trail.setAlpha(1);
  }

  resetBody() {
    this.setPosition(this.startPosition.x, this.startPosition.y);
    this.setAngle(0);
    this.body.setAngularVelocity(0);
    this.body.setVelocity(0);
  }

  handleHit() {
    this.isStopped = true;
  }

  preUpdate(time: number, delta: number) {
    super.preUpdate(time, delta);

    if (this.active) {
      this.trail.clear();

      // Trail: add new position to trail, if ball is moving //
      if (this.x !== this.head.x && this.y !== this.head.y) {
        this.head.x = this.x;
        this.head.y = this.y;
        this.points.push({
          x: this.head.x,
          y: this.head.y,
          lifespan: 10
        });
      }

      // Trail: If the ball is moving, render trail //
      if (this.points.length > 4) {
        this.trail.lineStyle(1, this.trailColor, 1.0);
        this.trail.beginPath();
        this.trail.lineStyle(0, this.trailColor, 1.0);
        this.trail.moveTo(this.points[0].x, this.points[0].y);
        for (var index = 1; index < this.points.length - 4; ++index) {
          var point = this.points[index];
          this.trail.lineStyle(linearInterpolation(index / (this.points.length - 4), 0, Scaling.getPixelbyDPR(10)), this.trailColor, 0.5);
          this.trail.lineTo(point.x, point.y);
        }
        let count = 0;
        for (var index = this.points.length - 4; index < this.points.length; ++index) {
          var point = this.points[index];
          this.trail.lineStyle(linearInterpolation(count++ / 4, Scaling.getPixelbyDPR(10), 0), this.trailColor, 1.0);
          this.trail.lineTo(point.x, point.y);
        }
        this.trail.strokePath();
        this.trail.closePath();
      }

      // Trail: Remove points when their lifespan is gone //
      for (var index = 0; index < this.points.length; ++index) {
        var point = this.points[index];
        point.lifespan -= 0.5;
        if (point.lifespan <= 0) {
          this.points.splice(index, 1);
          index -= 1;
        }
      }
    }
  }

}