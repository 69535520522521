import Scaling from "../../../configs/scaling";
import eventsController from "../../../controllers/eventsController";

export default class Goal extends Phaser.GameObjects.Sprite {
  declare body: Phaser.Physics.Arcade.Body;
  audioHitGoal: Phaser.Sound.BaseSound;
  audioHitPole: Phaser.Sound.BaseSound;
  poleLeft: Phaser.GameObjects.Zone;
  poleRight: Phaser.GameObjects.Zone;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, 'goal');
    this.scene.add.existing(this);

    /** General */
    this.setName('goal');
    this.setOrigin(0.5, 0);
    this.setScale(Scaling.GAME_BASE_DIFF_HEIGHT);

    /** Goal Pole: Left */
    this.poleLeft = this.scene.add.zone(this.getBounds().left + Scaling.getPixelbyDPR(1), this.getBounds().centerY, Scaling.getPixelbyDPR(3), this.height);
    
    /** Goal Pole: right */
    this.poleRight = this.scene.add.zone(this.getBounds().right + Scaling.getPixelbyDPR(2), this.getBounds().centerY, Scaling.getPixelbyDPR(3), this.height).setOrigin(1, 0.5);
    
    /** Physics: Goal */
    this.scene.physics.world.enable([this]);
    this.body.setSize(this.width, this.height - Scaling.getPixelbyDPR(35));
    this.body.setOffset(0);
    this.body.setImmovable(true);   
    
    /** Physics: Poles */
    this.scene.physics.world.enable([this.poleLeft, this.poleRight]);
    const poleLeftBody = this.poleLeft.body as Phaser.Physics.Arcade.Body;
    const poleRightBody = this.poleRight.body as Phaser.Physics.Arcade.Body;
    poleLeftBody.setImmovable(true);
    poleRightBody.setImmovable(true);

    /** Audio */
    this.audioHitGoal = this.scene.sound.add('hit_goal', { volume: 0.5 });
    this.audioHitPole = this.scene.sound.add('hit_pole', { volume: 0.5 });    

    /** Events */
    eventsController.on('goal-score', () => this.audioHitGoal.play());
    eventsController.on('goal-miss', () => this.audioHitPole.play());
  }
}