import Setup from "@/helpers/Setup";
import Phaser from "phaser";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";
import eventsController from "../../controllers/eventsController";

export default class Scoreboard extends Phaser.GameObjects.Container {
  background: Phaser.GameObjects.Image;
  divider: Phaser.GameObjects.Text;
  playerLogo: Phaser.GameObjects.Image;
  playerScore: Phaser.GameObjects.Text;
  enemyLogo: Phaser.GameObjects.Image;
  enemyScore: Phaser.GameObjects.Text;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    /** Background */
    this.background = this.scene.add.image(0, 0, 'scoreboard');

    /** Divider: A centered divider between the teams */
    this.divider = this.scene.add.text(0, 0, '-', {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(24)}px`,
      color: '#fff',
      align: 'center'
    }).setOrigin(0.5);

    /** Team: Player controlled team */
    this.playerLogo = this.scene.add.image(this.background.getBounds().left + Scaling.getPixelbyDPR(16), 0, `team_logo_${Setup.getValue('settings.game.player.team').value}`).setOrigin(0, 0.5).setScale(0.8);
    this.playerScore = this.scene.add.text(this.divider.getBounds().left - Scaling.getPixelbyDPR(8), 0, '0', {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(24)}px`,
      color: '#fff',
      align: 'right'
    }).setOrigin(1, 0.5);

    /** Team: Enemy team */
    this.enemyLogo = this.scene.add.image(this.background.getBounds().right - Scaling.getPixelbyDPR(16), 0, `team_logo_${Setup.getValue('settings.game.player.team').value}`).setOrigin(1, 0.5).setScale(0.8);
    this.enemyScore = this.scene.add.text(this.divider.getBounds().right + Scaling.getPixelbyDPR(8), 0, '0', {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(24)}px`,
      color: '#fff',
      align: 'left'
    }).setOrigin(0, 0.5);

    this.add([this.background, this.divider, this.playerLogo, this.playerScore, this.enemyLogo, this.enemyScore]);

    /** Events */
    eventsController.on('scoreboard-set-team', (data: any) => this.switchTeam(data.team));
    eventsController.on('scoreboard-update-score', (data: any) => this.updateScore(data.playerTeam, data.enemyTeam));
  }

  switchTeam(team: string) {
    this.enemyLogo.setTexture(`team_logo_${team}`);
  }

  updateScore(playerScore: number, enemyScore: number) {
    this.playerScore.setText(`${playerScore}`);
    this.enemyScore.setText(`${enemyScore}`);
  }
}