import Constants from "../../configs/constants";
import { ITeam, ITeams } from "../../interfaces/ITeams";
import EnemyTeam from "../enemyteam/enemyteam";
import Player from "../playerteam/player";
import eventsController from "../../controllers/eventsController";
import Match from "./match";
import Setup from "@/helpers/Setup";

interface ITournamentSettings {
  matches: number;
  playerTeam: Player;
  enemyTeam: EnemyTeam;
  hasInfiniteStage: boolean;
}

export default class Tournament {
  scene: Phaser.Scene;
  teamsJSON: ITeams;
  teams: ITeam[];
  bracket: ITeam[];
  matches: number;
  playerTeam: Player;
  enemyTeam: EnemyTeam;
  match!: Match;
  hasInfiniteStage!: boolean;

  currentStage = 0;

  constructor(scene: Phaser.Scene, settings: ITournamentSettings) {
    this.scene = scene;

    /** Settings */
    this.matches = settings.matches;
    this.playerTeam = settings.playerTeam;
    this.enemyTeam = settings.enemyTeam;
    this.hasInfiniteStage = settings.hasInfiniteStage;

    /** Tournament: Teams */
    this.teamsJSON = JSON.parse(Setup.getValue('settings.game.teams').value) as ITeams;
    this.teams = this.teamsJSON.teams;

    /** Tournament: Bracket */
    this.bracket = this.createBracket();
  }

  createBracket() {
    /** TEAMS: Filter out player team */
    const teamsAvailable = this.teams.filter(team => team.id !== this.playerTeam.team);

    /** TEAMS: Shuffle and pick random X amount */
    const teamsShuffled = [...teamsAvailable].sort(() => 0.5 - Math.random());
    const teamsPicked = teamsShuffled.slice(0, this.matches);

    /** TEAMS: Set settings based upon difficulty */
    teamsPicked.forEach(team => {
      const teamDifficultySettings = this.getDifficulty(team.difficulty);
      team.settings = teamDifficultySettings;
    })
    
    /** TEAMS: Check if tournament has infinite end-round */
    if (this.hasInfiniteStage) {
      const teamInfiniteStage = Phaser.Math.RND.pick(teamsAvailable);
      teamsPicked.push({...teamInfiniteStage, 
        settings: {
          goalsPerMinute: 9999, 
        },
        isInfinite: true 
      })
    }

    /** TEAMS: Sort on difficulty */
    teamsPicked.sort((team1, team2) => (team1.settings.goalsPerMinute > team2.settings.goalsPerMinute) ? 1 : -1);

    return teamsPicked;
  }

  getDifficulty(difficulty: number){
    /** DIFFICULTY: Get difficulty between 1 and 5 */
    const checkedDifficulty = Math.min(Math.max(difficulty, 1), 5); 

    /** DIFFICULTY: Set object to add values based upon difficulty */
    const difficultySettings = {
      goalsPerMinute: 5 * checkedDifficulty
    }

    return difficultySettings;
  }

  nextMatch() {
    /** MATCH: Reset to default */
    eventsController.emit('scoreboard-update-score', { playerTeam: 0, enemyTeam: 0 });

    /** MATCH: Set teams */
    this.enemyTeam.setTeam(this.bracket[this.currentStage]);
    eventsController.emit('scoreboard-set-team', { team: this.bracket[this.currentStage].id });

    /** MATCH: Start new match */
    this.match = new Match(this.scene, {
      playerTeam: this.playerTeam,
      enemyTeam: this.enemyTeam,
      duration: parseInt(Setup.getValue('settings.game.tournament.matchDurationRealtime')),
      isInfinite: this.bracket[this.currentStage].isInfinite ? true : false
    });

    /** POPUP: Show match introduction */
    eventsController.emit('popup-introduction-show', {
      playerTeam: this.playerTeam.team,
      enemyTeam: this.enemyTeam.team,
      enemyDifficulty: this.enemyTeam.difficulty,
      stage: this.currentStage,
      callback: () => this.match.start()
    })

    this.currentStage++;
  }
}