import Setup from "@/helpers/Setup";
import Phaser from "phaser";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";

export default class DialogMatchEnd extends Phaser.GameObjects.Container {
  returnFunction?: () => void;
  tween: Phaser.Tweens.Timeline;
  background: Phaser.GameObjects.Image;
  visual: Phaser.GameObjects.Image;
  textLeft: Phaser.GameObjects.Text;
  textRight: Phaser.GameObjects.Text;

  constructor(scene: Phaser.Scene) {
    super(scene, scene.cameras.main.width / 2, scene.cameras.main.height / 2);
    this.scene.add.existing(this);

    /** Background */
    this.background = this.scene.add.image(0, 0, 'dialog_background_end_match').setAlpha(0);
    this.background.setScale(Scaling.GAME_BASE_DIFF_HEIGHT);

    /** Visual */
    this.visual = this.scene.add.image(this.background.getBounds().centerX, this.background.getBounds().bottom, `dialog_player_end_match_won`).setOrigin(0.5, 1).setAlpha(0);

    /** Text */
    this.textLeft = this.scene.add.text(this.visual.getBounds().left - Scaling.getPixelbyDPR(12), 0, `${Setup.getCopy('general.you')}`, {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(28)}px`,
      color: '#fff',
      align: 'right'
    }).setOrigin(1, 0.5).setAlpha(0);
    this.textRight = this.scene.add.text(this.visual.getBounds().right + Scaling.getPixelbyDPR(12), 0, `${Setup.getCopy('general.won')}`, {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(28)}px`,
      color: '#fff',
      align: 'left'
    }).setOrigin(0, 0.5).setAlpha(0);

    this.add([this.background, this.visual, this.textLeft, this.textRight]);

    /** Animations */
    this.tween = this.createTimeline();
  }

  createTimeline() {
    const tween = this.scene.tweens.createTimeline();

    tween.add({
      targets: [this.background, this.visual],
      alpha: 1,
      y: `-=${Scaling.getPixelbyDPR(40)}`,
      ease: 'Back.Out',
      duration: 600
    })

    tween.add({
      targets: [this.textLeft],
      alpha: 1,
      x: `+=${Scaling.getPixelbyDPR(40)}`,
      ease: 'Back.Out',
      duration: 800,
      delay: this.scene.tweens.stagger(200, {})
    })

    tween.add({
      targets: [this.textRight],
      alpha: 1,
      x: `-=${Scaling.getPixelbyDPR(40)}`,
      ease: 'Back.Out',
      duration: 800,
      delay: this.scene.tweens.stagger(200, {}),
      offset: '-=1000'
    })

    tween.add({
      targets: [this.background, this.textLeft, this.textRight, this.visual],
      alpha: 0,
      y: `-=${Scaling.getPixelbyDPR(40)}`,
      ease: 'Back.In',
      duration: 600,
      onComplete: (step) => {
        step.targets.forEach(target => {
          const gameObject = target as Phaser.GameObjects.Image | Phaser.GameObjects.Text;
          gameObject.setY(gameObject.y + Scaling.getPixelbyDPR(40));
        });
        tween.pause();
        this.returnFunction ? this.returnFunction() : null;
      }
    })

    return tween;
  }

  resetTimeline() {
    this.background.setAlpha(0).setY(this.background.y + Scaling.getPixelbyDPR(40));
    this.visual.setAlpha(0).setY(this.visual.y + Scaling.getPixelbyDPR(40));
    this.textLeft.setAlpha(0).setX(this.textLeft.x - Scaling.getPixelbyDPR(40));
    this.textRight.setAlpha(0).setX(this.textRight.x + Scaling.getPixelbyDPR(40));
  }

  changeState(state: string) {
    switch (state) {
      case 'won':
        this.textLeft.setText(`${Setup.getCopy('general.you').toUpperCase()}`);
        this.textRight.setText(`${Setup.getCopy('general.won').toUpperCase()}`);
        this.visual.setTexture('dialog_player_end_match_won');
        break;
      case 'lost':
        this.textLeft.setText(`${Setup.getCopy('general.you').toUpperCase()}`);
        this.textRight.setText(`${Setup.getCopy('general.lost').toUpperCase()}`);
        this.visual.setTexture('dialog_player_end_match_lost');
        break;
      case 'tie':
        this.textLeft.setText(`${Setup.getCopy('general.itsa').toUpperCase()}`);
        this.textRight.setText(`${Setup.getCopy('general.tie').toUpperCase()}`);
        this.visual.setTexture('dialog_player_end_match_tie');
        break;
    }
  }

  start(state: string, returnFunction?: () => void) {
    returnFunction ? this.returnFunction = returnFunction : undefined;

    this.resetTimeline();
    this.changeState(state);

    if (!this.tween.isPlaying()) {
      this.tween.play();
    } else {
      this.tween.resume();
    }
  }

}