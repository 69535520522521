import Phaser from "phaser";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";
import eventsController from "../../controllers/eventsController";

export default class Playfield extends Phaser.GameObjects.Container {
  grid: { width: number, height: number, columns: number, rows: number };
  terrain: Phaser.GameObjects.Image[];
  spawnPositions: any[][];

  terrainTextures: string[] = ['field_light', 'field_dark'];

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    /** Playfield: Grid */
    this.grid = this.createGrid();

    /** Playfield: Terrain */
    this.terrain = this.addTerrainTexture();
    const terrainOffset = (this.scene.cameras.main.width - this.getBounds().width) / 2;
    this.setX(terrainOffset);

    /** Playfield: Player Positions */
    this.spawnPositions = this.getSpawnPositions();
  }

  createGrid() {
    const terrainTextureWidth = this.scene.textures.get(this.terrainTextures[0]).get(0).width * Scaling.GAME_BASE_DIFF_HEIGHT;
    const terrainTextureHeight = this.scene.textures.get(this.terrainTextures[0]).get(0).height * Scaling.GAME_BASE_DIFF_HEIGHT;
 
    const columns = Math.ceil(this.scene.cameras.main.width / terrainTextureWidth);
    const rows = Math.ceil(this.scene.cameras.main.height / terrainTextureHeight);

    return { width: terrainTextureWidth, height: terrainTextureHeight, columns, rows };
  }

  addTerrainTexture() {
    const terrain = [];

    /** Terrain: create terrain based upon grid + different texture types */
    for (let row = 0; row < this.grid.rows; row++) {
      for (let column = 0; column < this.grid.columns; column++) {
        
        /** Terrain: Switch between light and dark & Randomize sprite texture (frameAmount = (frames.length-1) - _Base frame ) */
        const texture = (row % 2 === 0) ? 0 : 1;
        const textureFrameAmount = this.scene.textures.get(this.terrainTextures[texture]).frameTotal - 1;
        const sprite = this.scene.add.sprite(0, 0, this.terrainTextures[texture], Phaser.Math.Between(0, textureFrameAmount - 1));
        sprite.x = column * this.grid.width;
        sprite.y = row * this.grid.height;
        sprite.setScale(Scaling.GAME_BASE_DIFF_HEIGHT);
        sprite.setOrigin(0, 0);

        terrain.push(sprite);
      }
    }

    this.add([...terrain]);

    return terrain;
  }

  getSpawnPositions() {
    let currentRow = 0;
    const positions = [[]] as any[];

    /** Create row|column grid with spawn positions */
    this.terrain.forEach(element => {
      const coords = {
        x: element.getBounds().x + (element.displayWidth / 2),
        y: element.getBounds().y + (element.displayHeight / 2)
      }

      /** Create new row */
      if (positions[currentRow].at(-1) && coords.x < positions[currentRow].at(-1).x) {
        positions.push([coords]);
        currentRow++;
      } 
      
      /** Add to current row */
      else {
        positions[currentRow].push(coords);
      }
    });

    return positions;
  }
}