import Scaling from "../configs/scaling";

interface IControlsSettings {
  debug?: boolean;
  onRelease: (key: any) => void;
}

export interface IControlsPath {
  x: number;
  y: number;
  delta: number;
  t: number;
}

export default class Controls {
  scene: Phaser.Scene;
  points: IControlsPath[];
  path?: Phaser.Curves.Path;
  debug?: boolean;
  debugGraphic: Phaser.GameObjects.Graphics;
  
  inGame = true;
  onRelease: (key: any) => void;

  constructor(scene: Phaser.Scene, settings: IControlsSettings) {
    this.scene = scene;

    /** Settings */
    this.onRelease = settings.onRelease;
    this.debug = settings.debug;
    this.points = [];
    this.path = undefined;
    this.debugGraphic = this.scene.add.graphics({ x: 0, y: 0 });

    /** Input */
    this.scene.input.on('pointerdown', (pointer: any) => this.onPointerDown(pointer));
    this.scene.input.on('pointerup', (pointer: any) => this.onPointerUp(pointer));
    this.scene.input.on('gameout', (pointer: any) => this.inGame = false);
    this.scene.input.on('gameover', (pointer: any) => this.inGame = true);
  }

  onPointerDown(pointer: any) {
    if(this.inGame){
      this.points = [];

      if (this.debug) {
        this.debugGraphic.lineStyle(Scaling.getPixelbyDPR(3), 0xFFF, 1);
        this.debugGraphic.beginPath();
      }
  
      this.path = new Phaser.Curves.Path(0, 0);
      this.path.moveTo(pointer.x, pointer.y);
    }
  }

  onPointerUp(pointer: any) {
    if(this.inGame && this.path){
      if (this.debug) {
        this.debugGraphic.closePath();
        this.debugGraphic.clear();
      }
      this.path.destroy();
      this.path = undefined;

      this.onRelease(this.points);
    }
  }

  update(time: number, delta: number) {
    if (this.path && this.inGame && this.scene.input.activePointer.isDown) {
      this.points.push({ x: this.scene.input.activePointer.x, y: this.scene.input.activePointer.y, t: time, delta: delta })
      this.path.lineTo(this.scene.input.activePointer.x, this.scene.input.activePointer.y);

      if (this.debug) {
        this.path.draw(this.debugGraphic);
      }
    }
  }
}