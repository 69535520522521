import Phaser from "phaser";
import Scoreboard from "../components/stadium/scoreboard";
import eventsController from "../controllers/eventsController";
import Constants from "../configs/constants";
import Scaling from "../configs/scaling";
import CircularProgress from "phaser3-rex-plugins/plugins/circularprogress";

export default class HUD extends Phaser.Scene {
  isDebugmode!: boolean;
  fpsTracker!: Phaser.GameObjects.Text;
  scoreboard!: Scoreboard;

  constructor() {
    super({ key: "hud" });
  }

  init(data: any) {
    this.isDebugmode = data.debug || false;
  }

  create() {
    /** Debug: Set FPS tracker (optional) */
    this.fpsTracker = this.add.text(this.cameras.main.width - Scaling.getPixelbyDPR(16), Scaling.getPixelbyDPR(16), `${0} - FPS`, {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(16)}px`,
      color: '#fff',
      align: 'right'
    }).setOrigin(1, 0);
    this.fpsTracker.setActive(this.isDebugmode).setVisible(this.isDebugmode);

    /** Scoreboard */
    this.scoreboard = new Scoreboard(this, 0, 0);

    /** Clock: Timer for match time */
    const clock = this.add.text(this.scoreboard.getBounds().right + (36 * Scaling.DPR), 5 * Scaling.DPR, '0”', {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(12)}px`,
      color: '#fff',
      align: 'left'
    }).setOrigin(0.5, 1);

    /** Clock: Visual aid */
    const clockProgression = new CircularProgress(this, {
      x: clock.getBounds().centerX, y: clock.getBounds().centerY,
      radius: Scaling.getPixelbyDPR(20),
      trackColor: 0x000000,
      barColor: 0xFFFFFF,
      thickness: 0.15,
      valuechangeCallback: () => { }
    });
    this.add.existing(clockProgression);    

    /** Container: Add previous elements in movable container */
    const uiContainer = this.add.container(this.cameras.main.width / 2, Scaling.getPixelbyDPR(82));
    uiContainer.add([this.scoreboard, clockProgression, clock]);

    /** Events */
    eventsController.on('hud-update-timer', (data: any) => {
      clock.setText(`${data.time}”`);
      clockProgression.setValue(data.progression);
    })
  }

  update() {
    if (this.isDebugmode) {
      this.fpsTracker.setText(`${Math.floor(this.game.loop.actualFps)} - FPS`);
    }
  }
}