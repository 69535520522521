import Phaser from "phaser";
import Scaling from "../../configs/scaling";
import { IFormations } from "../../interfaces/IFormations";
import EnemyGoalkeeper from "./objects/enemygoalkeeper";
import EnemyPlayer from "./objects/enemyplayer";
import Goal from "../playfield/objects/goal";
import { ITeam, ITeamSettings } from "../../interfaces/ITeams";
import Setup from "@/helpers/Setup";

interface IEnemyTeamSettings {
  goal: Goal,
  spawnPositions: any[][]
}

export default class EnemyTeam extends Phaser.GameObjects.Group {
  goal: Goal;
  goalkeeper: EnemyGoalkeeper;
  spawnPositions: any[][];
  formationsJSON: IFormations;
  settings!: ITeamSettings;
  difficulty!: number;

  team = '';
  previousFormation = 1;
  amount = 11;
  goalNoSpawnOffset: number = Scaling.getPixelbyDPR(20);

  constructor(scene: Phaser.Scene, settings: IEnemyTeamSettings) {
    super(scene);

    /** Settings */
    this.goal = settings.goal;
    this.formationsJSON = JSON.parse(Setup.getValue('settings.game.formations').value) as IFormations;
    this.spawnPositions = settings.spawnPositions;

    /** Goalkeeper */
    this.goalkeeper = new EnemyGoalkeeper(this.scene, { goal: settings.goal });

    /** Players */
    for (let index = 0; index < (this.amount - 1); index++) {
      const player = new EnemyPlayer(this.scene, 0, 0, index + 1);
      this.add(player)
    }
  }

  placeGoalkeeper() {
    this.goalkeeper.place();
  }

  setTeam(team: ITeam) {
    this.team = team.id;
    this.difficulty = team.difficulty;
    this.settings = team.settings;
  }

  switchFormation() {
    /** Formation: Get available formations, filter the previous one out */
    const availableFormations = this.formationsJSON.formations.filter((formation: any) => formation.id !== this.previousFormation);
    const formation = Phaser.Math.RND.pick(availableFormations) as any;

    /** Formation: Save chosen formation to be filtered out */
    this.previousFormation = formation.id;

    /** Players: Get active players and remove them from the playfield */
    const playersActive = this.getMatching('active', true);
    playersActive.forEach(player => player.setActive(false).setVisible(false).setPosition(0, 0));

    /** Spawn Positions: Check available spawn positions based upon goal position and screen width */
    const spawnPositionsAvailable = this.spawnPositions.filter(row => row[0].y > this.goal.getBounds().bottom + this.goalNoSpawnOffset);
    const formationDifference = this.spawnPositions[0].length - formation.playfield[0].length;
    spawnPositionsAvailable.forEach((row: any, index: number) => {
      const centered = row.filter((column: any, index: number) => index >= (formationDifference / 2) && index < row.length - (formationDifference / 2));
      spawnPositionsAvailable[index] = centered;
    })

    /** Players: Place new players in formation */
    formation.playfield.forEach((row: number[], rowIndex: number) => {
      row.forEach((playerAmount, columnIndex) => {
        if (playerAmount) {
          const playerPosition = spawnPositionsAvailable[rowIndex][columnIndex];
          const player = this.getFirstDead() as EnemyPlayer;

          if(player){
            // Player: Set texture to correct team + correct player amount ( 1 or 2 ) //
            player.setSprite(this.team, playerAmount);
  
            // Player: Reset the body size to scale to correct player amount //
            player.setBodySize();
  
            // Player: Set the position to correct position and revive it //
            player.setPosition(playerPosition.x, playerPosition.y);
            player.setActive(true).setVisible(true);
          }
        }
      })
    })
  }
}