import Phaser from "phaser";
import Ball from "../components/playfield/objects/ball";
import EnemyTeam from "../components/enemyteam/enemyteam";
import Playfield from "../components/playfield/playfield";
import Grandstand from "../components/stadium/grandstand";
import Player from "../components/playerteam/player";
import Scaling from "../configs/scaling";
import Tournament from "../components/systems/tournament";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import { ScoreDataDefault, ScoreGameTypes, ScoreResultTypes } from "@/interfaces/ISessionFile";
import Goal from "../components/playfield/objects/goal";
import PlayfieldLine from "../components/playfield/objects/playfieldline";
import Controls, { IControlsPath } from "../controllers/controlController";
import eventsController from "../controllers/eventsController";
import DialogIntroduction from "../components/dialog/dialogIntroduction";
import EnemyPlayer from "../components/enemyteam/objects/enemyplayer";
import EnemyGoalkeeper from "../components/enemyteam/objects/enemygoalkeeper";
import DialogMatchEnd from "../components/dialog/dialogMatchEnd";
import Background from "./background";
import Setup from "@/helpers/Setup";
import { millisToMinutesAndSeconds, remapValue } from "../configs/helpers";
import scoreController from "@/utils/game/ScoreController";

export default class Main extends Phaser.Scene {
  playfield!: Playfield;
  grandstand!: Grandstand;
  goal!: Goal;
  playFieldLineGoal!: PlayfieldLine;
  playfieldLineBall!: PlayfieldLine;
  ball!: Ball;
  player!: Player;
  enemyTeam!: EnemyTeam;
  controls!: Controls;
  tournament!: Tournament;
  freekickPosition!: number;
  playerTeam!: string;
  dialogIntroduction!: DialogIntroduction;
  dialogMatchEnd!: DialogMatchEnd;
  startTime!: Date;

  constructor() {
    super({ key: "game" });
  }

  init() {
    this.playerTeam = Setup.getValue('settings.game.player.team').value;
    this.freekickPosition = this.cameras.main.height - Scaling.getPixelbyDPR(80 * Scaling.GAME_BASE_DIFF_HEIGHT);
    this.startTime = new Date();

    /** SCENES: HUD */
    this.scene.launch('hud', { debug: false });
  }

  create() {
    this.game.events.emit('start');
    
    /** SAVEFILE */
    sessionSaveFile.create({
      type: ScoreGameTypes.INFINITE,
      stats: ['goals', 'shots', 'playtime']
    });

    /** WORLD: Playfield */
    this.playfield = new Playfield(this, 0, 0);

    /** WORLD: Grandstand 
     * Offset: Add offset based upon min supported height (548px) 
    */
    const grandstandOffset = Math.max(remapValue(window.innerHeight, 667, 548, 0, 110), 0);
    this.grandstand = new Grandstand(this, this.cameras.main.centerX, -Scaling.getPixelbyDPR(grandstandOffset));

    /** WORLD: Goal */
    this.goal = new Goal(this, this.cameras.main.centerX, this.grandstand.getBounds().bottom);
    this.playFieldLineGoal = new PlayfieldLine(this, this.cameras.main.centerX, this.goal.getBounds().bottom, 'lines_goal');

    /** WORLD: Ball */
    this.playfieldLineBall = new PlayfieldLine(this, this.cameras.main.centerX, this.freekickPosition, 'lines_kickoff');
    this.ball = new Ball(this, this.cameras.main.centerX, this.freekickPosition);

    /** PLAYER */
    this.player = new Player(this, this.ball.getBounds().left - Scaling.getPixelbyDPR(5), this.ball.getBounds().bottom - Scaling.getPixelbyDPR(15), this.playerTeam);

    /** ENEMY: Team */
    this.enemyTeam = new EnemyTeam(this, {
      goal: this.goal,
      spawnPositions: this.playfield.spawnPositions
    });

    /** TOURNAMENT */
    this.tournament = new Tournament(this, {
      matches: parseInt(Setup.getValue('settings.game.tournament.matchAmount')),
      playerTeam: this.player,
      enemyTeam: this.enemyTeam,
      hasInfiniteStage: Setup.getValue('settings.game.tournament.hasInfiniteRound')
    })

    /** POPUP: Introduction */
    this.dialogIntroduction = new DialogIntroduction(this);
    this.dialogMatchEnd = new DialogMatchEnd(this);

    /** EVENTS */
    eventsController.on('match-won', () => {
      eventsController.emit('grandstand-cheer');
      this.dialogMatchEnd.start('won', () => {
        if(this.tournament.bracket[this.tournament.currentStage]){
          this.tournament.nextMatch();
        }else {
          this.gameover();
        }
      });
    })
    eventsController.on('match-tie', () => {
      this.dialogMatchEnd.start('tie', () => this.gameover());
    });    
    eventsController.on('match-lost', () => {
      this.dialogMatchEnd.start('lost', () => this.gameover());
    });

    /** CONTROLS */
    this.controls = new Controls(this, {
      onRelease: (points: IControlsPath[]) => this.tournament.match.shootBall(points)
    })

    /** AUDIO */
    const backgroundScene = this.scene.get("background") as Background;
    backgroundScene.playAudio();

    /** FLOW: Start tournament */
    this.time.delayedCall(400, () => this.tournament.nextMatch());

  }
  update(time: number, delta: number) {
    /** Collision: Goal/Grandstand <====> Ball */
    this.physics.world.collide([this.goal, this.goal.poleLeft, this.goal.poleRight, this.grandstand], this.ball, (target, ball) => {
      const _target = target as Phaser.Physics.Arcade.Sprite;
      const _ball = ball as Ball;

      if (_target.name === 'goal' && ball.body.top >= _target.body.bottom) {
        eventsController.emit('match-score', { team: 'player', ballX: _ball.x, ballY: _ball.y });
      } else if (_target.name === 'grandstand') {
        eventsController.emit('grandstand-hit');
      } else if (_target.name === 'goal') {
        eventsController.emit('goal-miss');
      }
    });

    /** Collision: Enemy Team <====> Ball */
    this.physics.world.collide(this.ball, this.enemyTeam, (ball, player) => {
      const _player = player as EnemyPlayer;
      _player.handleHit();

      eventsController.emit('ball-stopped');
    });

    /** Collision: Goalkeeper <====> Ball */
    this.physics.world.collide(this.ball, this.enemyTeam.goalkeeper, (ball, goalkeeper) => {
      const _goalkeeper = goalkeeper as EnemyGoalkeeper;
      _goalkeeper.handleHit();
    });

    /** Controls */
    this.controls.update(time, delta);
  }

  gameover() {
    this.shutdown();
  }

  shutdown() {
    /** EVENTS: Cleanup */
    eventsController.removeAllListeners();

    /** Scenes */
    this.scene.stop('hud');
    this.scene.stop();

    /** PROGRESSION: update playtime */
    sessionSaveFile.updateValue('playtime', millisToMinutesAndSeconds(new Date().getTime() - this.startTime.getTime()));

    /** END LEVEL */
    const result = scoreController.getResult();

    /** RESULT: Adjust result based upon infinite round inclusion + all matches finished  */
    if(!Setup.getValue('settings.game.tournament.hasInfiniteRound') && !this.tournament.bracket[this.tournament.currentStage]){
      result.resultType = ScoreResultTypes.FINISHED;
    }

    this.game.events.emit('end', result);
  }
}