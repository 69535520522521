import Phaser from "phaser";
import Scaling from "../../../configs/scaling";

export default class PlayfieldLine extends Phaser.GameObjects.Sprite {
  constructor(scene: Phaser.Scene, x: number, y: number, texture: string) {
    super(scene, x, y, texture);
    this.scene.add.existing(this);

    /** General */
    this.setScale(Scaling.GAME_BASE_DIFF_HEIGHT);
    this.setOrigin(0.5, 0);
  }
}