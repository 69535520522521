import sessionSaveFile from "@/utils/game/SessionSaveFile";
import Constants from "../../configs/constants";
import { ITeams } from "../../interfaces/ITeams";
import EnemyTeam from "../enemyteam/enemyteam";
import { IControlsPath } from "../../controllers/controlController";
import eventsController from "../../controllers/eventsController";
import Setup from "@/helpers/Setup";

export default class Match {
  scene: Phaser.Scene;
  isInfinite: boolean;
  playerTeam: string;
  enemyTeam: EnemyTeam;
  duration?: number;
  durationTimer?: Phaser.Time.TimerEvent;
  enemyScoreTimer?: Phaser.Time.TimerEvent;
  scores: any;

  hasScored = false;
  hasStarted = false;
  canShoot = true;
  hasBurnedLoyaltyPoints = false;
  ballResetTimer: Phaser.Time.TimerEvent | null = null;

  constructor(scene: Phaser.Scene, settings: any) {
    this.scene = scene;

    /** Settings */
    this.isInfinite = settings.isInfinite;
    this.playerTeam = settings.playerTeam;
    this.enemyTeam = settings.enemyTeam;

    /** Match: Set score */
    this.scores = {
      player: 0,
      enemy: 0
    }

    /** Match: Set timers */
    if (!this.isInfinite) {
      this.duration = settings.duration;
      this.durationTimer = this.createMatchDurationTimer(parseInt(Setup.getValue('settings.game.tournament.matchDurationDisplayed')), parseInt(Setup.getValue('settings.game.tournament.matchDurationRealtime')));
      this.enemyScoreTimer = this.createEnemyScoreInterval();
      eventsController.emit('hud-update-timer', { time: 0, progression: 0 });
    } else {
      // this.scene.savefile.won = true;
      eventsController.emit('hud-update-timer', { time: '∞', progression: 0 });
    }

    /** Match: Place enemy team on playfield */
    this.enemyTeam.placeGoalkeeper();
    this.setFormation();

    /** Events */
    eventsController.off('match-score');
    eventsController.on('match-score', (data: any) => this.scoreGoal(data.team, data.ballX, data.ballY));
  }

  createMatchDurationTimer(displayTime: number, actualTime: number) {
    const timer = this.scene.time.addEvent({
      delay: 1000,
      timeScale: displayTime / actualTime,
      repeat: displayTime,
      paused: true,
      callback: () => {
        if (this.durationTimer) {
          const time = displayTime - this.durationTimer.getRepeatCount();
          eventsController.emit('hud-update-timer', { time: time, progression: time / displayTime });
          if (this.durationTimer.getRepeatCount() === 0) {
            this.stop();
          }
        }
      }
    })

    return timer;
  }

  createEnemyScoreInterval() {
    const timer = this.scene.time.addEvent({
      delay: ((60 - 5) / this.enemyTeam.settings.goalsPerMinute) * 1000,
      repeat: (this.enemyTeam.settings.goalsPerMinute - 1),
      callback: () => {
        this.scoreGoal('enemy');
      }
    })

    return timer;
  }

  start() {
    this.hasStarted = true;

    /** Timer: If non-infinite match, start timer */
    if (this.durationTimer) this.durationTimer.paused = false;
  }

  stop() {
    this.canShoot = false;

    /** Timer: if non-infinite match, remove timers */
    if (this.durationTimer && this.enemyScoreTimer) {
      this.scene.time.removeEvent(this.durationTimer);
      this.scene.time.removeEvent(this.enemyScoreTimer);
    }

    /** State: Infinite games always result in a loss */
    if (!this.isInfinite && this.scores.player > this.scores.enemy) {
      eventsController.emit('match-won');
    } else {
      if (this.scores.player === this.scores.enemy) {
        eventsController.emit('match-tie');
      } else {
        eventsController.emit('match-lost');
      }
    }
  }

  scoreGoal(team: string, ballX?: number, ballY?: number) {
    if (team === 'player') {
      /** Match: Set flag for scoring */
      if (this.hasScored) { return false; }
      this.hasScored = true;

      /** Match: add score */
      this.scores.player++;

      /** Timer: Stop ball position reset timer */
      if (this.ballResetTimer) this.scene.time.removeEvent(this.ballResetTimer);

      /** Events */
      eventsController.emit('goal-score');
      eventsController.emit('grandstand-cheer');
      eventsController.emit('ball-explode', { x: ballX, y: ballY });

      /** Savefile */
      sessionSaveFile.incrementValue('totalScore', 1);
      sessionSaveFile.incrementValue('goals', 1);

      /** Match: Reset ball & set new formation after delay */
      this.scene.time.delayedCall(800, () => {
        this.setFormation();
      })
    }

    else {
      this.scores.enemy++;
    }

    eventsController.emit('scoreboard-update-score', { playerTeam: this.scores.player, enemyTeam: this.scores.enemy });
  }

  shootBall(points: IControlsPath[]) {
    /** Shoot: No movement */
    if (!points.length) {
      return false;
    }

    /** Shoot: Too slight movement */
    if ((points[0].x - points[points.length - 1].x) < 5 && (points[0].y - points[points.length - 1].y) < 5) {
      return false;
    }

    /** Shoot: If able to shoot & set timer to reset ball position */
    if (this.hasStarted && this.canShoot) {
      this.canShoot = false;

      this.ballResetTimer = this.scene.time.delayedCall(Constants.MATCH_BALL_RESET_DURATION, () => {
        /** Match: If match is infinite, stop match else reset ball position */
        if (!this.isInfinite) {
          this.canShoot = true;
          eventsController.emit('ball-reset');
        } else {
          this.stop();
        }
      });

      /** Shoot: Make player shoot ball */
      eventsController.emit('ball-shoot', { path: points });
    }
  }

  setFormation() {
    this.hasScored = false;
    this.canShoot = true;

    eventsController.emit('ball-reset');
    this.enemyTeam.switchFormation();
  }
}