import sessionSaveFile from "@/utils/game/SessionSaveFile";
import Phaser from "phaser";
import Scaling from "../../configs/scaling";
import eventsController from "../../controllers/eventsController";

export default class Player extends Phaser.GameObjects.Sprite {
  team: string;

  constructor(scene: Phaser.Scene, x:number, y:number, team: string) {
    super(scene, x, y, 'player');
    this.scene.add.existing(this);
    
    /** Settings */
    this.team = team;

    /** General */
    this.setScale(Scaling.GAME_BASE_DIFF_HEIGHT);

    /** Events */
    eventsController.on('ball-shoot', () => this.shoot());
  }

  shoot(){
    sessionSaveFile.incrementValue('shots', 1);
    
    this.setFrame(1);
    this.scene.time.delayedCall(400, () => this.setFrame(0));
  }  
}