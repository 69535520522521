import Phaser from "phaser";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";
import { ITeams } from "../../interfaces/ITeams";
import eventsController from "../../controllers/eventsController";
import Rating from "../hud/rating";
import Setup from "@/helpers/Setup";

export default class DialogIntroduction extends Phaser.GameObjects.Container {
  returnFunction?: () => void;
  enemyRating: Rating;
  timeline: Phaser.Tweens.Timeline;
  background: Phaser.GameObjects.Image;
  playerLogo: Phaser.GameObjects.Image;
  enemyLogo: Phaser.GameObjects.Image;
  stageText: Phaser.GameObjects.Text;
  playerText: Phaser.GameObjects.Text;
  difficulty!: number;

  constructor(scene: Phaser.Scene) {
    super(scene, scene.cameras.main.centerX, scene.cameras.main.centerY);
    this.scene.add.existing(this);

    /** Settings */
    const teamsJSON = JSON.parse(Setup.getValue('settings.game.teams').value) as ITeams
    const teams = teamsJSON.teams;

    /** Background */
    this.background = this.scene.add.image(0, 0, 'dialog_background_introduction');
    this.background.setScale(Scaling.GAME_BASE_DIFF_HEIGHT);

    /** Stage */
    this.stageText = this.scene.add.text(0, this.background.getBounds().top + Scaling.getPixelbyDPR(8), `Stage 1`, {
      fontFamily: Constants.FONT_REGULAR,
      fontSize: `${Scaling.getPixelbyDPR(12)}px`,
      color: '#fff',
      align: 'center'
    }).setOrigin(0.5, 0);

    /** Player: Logo & portrait team */
    this.playerLogo = this.scene.add.image(this.background.getBounds().centerX - Scaling.getPixelbyDPR(64), this.background.getBounds().centerY - Scaling.getPixelbyDPR(20), `team_logo_${Setup.getValue('settings.game.player.team').value}`).setOrigin(1, 0.5);
    this.playerText = this.scene.add.text(this.playerLogo.getBounds().centerX, this.background.getBounds().centerY + Scaling.getPixelbyDPR(20), `You`, {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(16)}px`,
      color: '#fff',
      align: 'center'
    }).setOrigin(0.5, 0.5);

    /** Enemy: Logo & portrait team */
    this.enemyLogo = this.scene.add.image(this.background.getBounds().centerX + Scaling.getPixelbyDPR(64), this.background.getBounds().centerY - Scaling.getPixelbyDPR(20), `team_logo_${teams[0].id}`).setOrigin(0, 0.5);
    this.enemyRating = new Rating(this.scene, this.enemyLogo.getBounds().centerX, this.background.getBounds().centerY + Scaling.getPixelbyDPR(20), {
      maxRating: 5
    });

    this.add([this.background, this.playerLogo, this.enemyLogo, this.stageText, this.playerText, this.enemyRating]);
    this.iterate((item: Phaser.GameObjects.Image | Phaser.GameObjects.Text) => item.setAlpha(0));

    /** Animation: Create fly-in animation */
    this.timeline = this.createTimeline();

    /** Events */
    eventsController.on('popup-introduction-show', (data: any) => this.start(data.playerTeam, data.enemyTeam, data.enemyDifficulty, data.stage, data.callback));
  }

  createTimeline() {
    const tween = this.scene.tweens.createTimeline();

    tween.add({
      targets: [this.background, this.stageText],
      alpha: 1,
      y: `-=${Scaling.getPixelbyDPR(40)}`,
      ease: 'Back.Out',
      duration: 600
    })

    tween.add({
      targets: [this.playerLogo],
      alpha: 1,
      x: `+=${Scaling.getPixelbyDPR(40)}`,
      ease: 'Back.Out',
      duration: 800,
      delay: this.scene.tweens.stagger(200, {})
    })

    tween.add({
      targets: [this.enemyLogo],
      alpha: 1,
      x: `-=${Scaling.getPixelbyDPR(40)}`,
      ease: 'Back.Out',
      duration: 800,
      delay: this.scene.tweens.stagger(200, {}),
      offset: '-=1000'
    })

    tween.add({
      targets: [this.playerText],
      alpha: 1,
      y: `+=${Scaling.getPixelbyDPR(40)}`,
      ease: 'Cubic.Out',
      duration: 800,
      delay: this.scene.tweens.stagger(200, {}),
      offset: '-=100',
      onComplete: () => {
        this.scene.time.addEvent({
          delay: 150, callback: () => {
            this.enemyRating.setRating(this.difficulty);
          }
        })
      }
    })

    tween.add({
      targets: [this.enemyRating],
      alpha: 1,
      y: `+=${Scaling.getPixelbyDPR(40)}`,
      ease: 'Cubic.Out',
      duration: 800,
      delay: this.scene.tweens.stagger(200, {}),
      offset: '-=800',
    })

    tween.add({
      targets: [this.background, this.playerLogo, this.enemyLogo, this.playerText, this.stageText, this.enemyRating],
      alpha: 0,
      y: `-=${Scaling.getPixelbyDPR(40)}`,
      ease: 'Back.In',
      duration: 600,
      onComplete: (step) => {
        step.targets.forEach(target => {
          const gameObject = target as Phaser.GameObjects.Image | Phaser.GameObjects.Text;
          gameObject.setY(gameObject.y + Scaling.getPixelbyDPR(40));
        })

        tween.pause();
        this.returnFunction ? this.returnFunction() : null;
      }
    })

    return tween;
  }

  resetTimeline() {
    this.background.setAlpha(0).setY(this.background.y + Scaling.getPixelbyDPR(40));
    this.playerLogo.setAlpha(0).setX(this.playerLogo.x - Scaling.getPixelbyDPR(40));
    this.enemyLogo.setAlpha(0).setX(this.enemyLogo.x + Scaling.getPixelbyDPR(40));
    this.stageText.setAlpha(0).setY(this.stageText.y + Scaling.getPixelbyDPR(40));
    this.playerText.setAlpha(0).setY(this.playerText.y - Scaling.getPixelbyDPR(40));
    this.enemyRating.setAlpha(0).setY(this.enemyRating.y - Scaling.getPixelbyDPR(40));
  }

  setTeams(player: string, enemy: string) {
    this.playerLogo.setTexture(`team_logo_${player}`);
    this.enemyLogo.setTexture(`team_logo_${enemy}`);
  }

  setDifficulty(difficulty: number) {
    this.difficulty = difficulty;
  }

  start(player: string, enemy: string, difficulty: number, stage: number, returnFunction?: () => void) {
    if (returnFunction) this.returnFunction = returnFunction;

    this.enemyRating.reset();
    this.resetTimeline();

    this.stageText.setText(`${Setup.getCopy('general.stage')} ${stage + 1}/${parseInt(Setup.getValue('settings.game.tournament.matchAmount'))}`);
    this.setTeams(player, enemy);
    this.setDifficulty(difficulty);

    if (!this.timeline.isPlaying()) {
      this.timeline.play();
    } else {
      this.timeline.resume();
    }
  }

}