import Boot from "./states/boot";
import Background from "./states/background";
import Load from "./states/load";
import HUD from "./states/hud";
import Main from "./states/main";
import Scaling from "./configs/scaling";

import Phaser from "phaser";

export default class Game {

    private gameInstance: Phaser.Game;
    private gameLoaded = false;

    constructor(parentWidth: number, parentHeight: number) {
        // Adjust game width/height if resolution ratio is different from base //
        Scaling.updateResolutionRatio(parentWidth, parentHeight);

        //Construct config
        const config: Phaser.Types.Core.GameConfig = {
            type: Phaser.AUTO,
            width: Scaling.GAME_WIDTH,
            height: Scaling.GAME_HEIGHT,
            scale: {
                parent: "game",
                zoom: 1 / Scaling.DPR,
                width: Scaling.GAME_WIDTH * Scaling.DPR,
                height: Scaling.GAME_HEIGHT * Scaling.DPR,
                mode: Phaser.Scale.FIT,
                autoCenter: Phaser.Scale.CENTER_BOTH
            },
            physics: {
                default: "arcade",
                arcade: {
                    debug: false
                }
            },
            fps: {
                target: 60,
                forceSetTimeOut: false
            },
            render: {
                roundPixels: true
            },
            scene: [
                Boot,
                Background,
                Load,
                Main,
                HUD
            ]
        };

        //Create and run game
        this.gameInstance = new Phaser.Game(config);
    }

    //Events
    setEventListener(name: string, listener?: Function) {
        this.removeEventListener(name);

        //Only set, if actually provided
        if (listener) {
            this.gameInstance.events.on(name, listener);
        }
    }

    removeEventListener(name: string) {
        this.gameInstance.events.off(name);
    }

    //(Re)start game
    startGame() {
        const scene = this.gameInstance.scene.getScene("game");
        if (scene) {
            scene.scene.start();
        }
    }

    //Stop game
    stopGame() {
        const scene = this.gameInstance.scene.getScene("game");
        if (scene) {
            scene.scene.stop();
        }
    }

    //Start loading
    loadGame() {
        if (!this.gameLoaded) {
            this.startScene("load");
            this.gameLoaded = true;
        }
    }

    // Start a scene in the game
    startScene(key: string) {
        const scene = this.gameInstance.scene.getScene(key);
        if (scene) {
            scene.scene.start();
        }
    }

    // Stop scene in the game
    stopScene(key: string) {
        const scene = this.gameInstance.scene.getScene(key);
        if (scene) {
            scene.scene.stop();
        }
    }

    // Hide the loading view
    endLoad() {
        if (this.gameLoaded) {
            this.stopScene("load");
        }
    }    

    // Set background color
    setBackgroundColor(color: number) {
        const scene = this.gameInstance.scene.getScene("background") as any;
        if (scene) {
            scene.setColor(color);
        }
    }

    setMutedStatus(state: boolean) {
        this.gameInstance.sound.mute = state
    }

    // Used to destroy phaser instance
    destroy() {
        this.gameInstance.destroy(true);
    }
}