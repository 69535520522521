import Phaser from "phaser";
import Scaling from "../../configs/scaling";
import eventsController from "../../controllers/eventsController";

export default class Grandstand extends Phaser.GameObjects.Sprite {
  declare body: Phaser.Physics.Arcade.Body;
  audioCheer: Phaser.Sound.BaseSound;
  audioHitStand: Phaser.Sound.BaseSound;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, 'grandstand');
    this.scene.add.existing(this);

    /** General */
    this.setName('grandstand');
    this.setOrigin(0.5, 0);
    this.setScale(Scaling.GAME_BASE_DIFF_HEIGHT);
    
    /** Physics */
    this.scene.physics.world.enable(this);
    this.body.setSize(this.width, this.height - Scaling.getPixelbyDPR(15));
    this.body.setOffset(0);
    this.body.setImmovable(true);

    /** Animations */
    this.anims.create({
      key: 'cheer',
      frames: this.anims.generateFrameNumbers('grandstand', { frames: [0, 1] }),
      frameRate: 8,
      repeat: 2
    });

    /** Sound */
    this.audioCheer = this.scene.sound.add('cheer', { volume: 0.5 });
    this.audioHitStand = this.scene.sound.add('hit_stand', { volume: 0.5 });

    /** Events */
    eventsController.on('grandstand-cheer', () => this.cheer());
    eventsController.on('grandstand-hit', () => this.audioHitStand.play());
  }

  cheer() {
    this.play('cheer');

    this.scene.tweens.add({
      targets: this.audioCheer,
      volume: { from: 0, to: 0.5 },
      duration: 1000,
      yoyo: true
    });
    this.audioCheer.play();
  }
}