import Phaser from "phaser";
import Scaling from "../../../configs/scaling";

export default class EnemyGoalkeeper extends Phaser.GameObjects.Sprite {
  goal: Phaser.GameObjects.Sprite;
  speed: any;
  patrolPath: any;
  declare body: Phaser.Physics.Arcade.Body;

  constructor(scene: Phaser.Scene, settings: any) {
    super(scene, 0, 0, 'goalkeeper');
    this.scene.add.existing(this);

    /** Settings */
    this.goal = settings.goal;
    this.speed = {
      current: 0,
      min: Scaling.getPixelbyDPR(50 * Scaling.GAME_BASE_DIFF_HEIGHT),
      max: Scaling.getPixelbyDPR(100 * Scaling.GAME_BASE_DIFF_HEIGHT)
    };
    this.patrolPath = {
      min: this.goal.getBounds().left + Scaling.getPixelbyDPR(12),
      max: this.goal.getBounds().right - Scaling.getPixelbyDPR(12)
    }

    /** General */
    this.setActive(false).setVisible(false);
    this.setScale(Scaling.GAME_BASE_DIFF_HEIGHT);
    this.setY(this.goal.getBounds().bottom - Scaling.getPixelbyDPR(18));

    /** Physics */
    this.scene.physics.world.enable(this);
    this.body.setImmovable(true);
    this.body.setBounce(1, 1);
    this.body.setSize(this.width - Scaling.getPixelbyDPR(10), this.height - Scaling.getPixelbyDPR(10));

    /** Animations */
    this.anims.create({
      key: 'walk',
      frames: this.anims.generateFrameNumbers(this.texture.key, { frames: [0, 1, 2] }),
      frameRate: 6,
      repeat: -1
    });
  }

  place() {
    /** Position: Place in the middle of the goal */
    this.setX(this.goal.getBounds().centerX).setActive(true).setVisible(true);

    /** Speed: Adjust movement speed of goalkeeper */
    this.speed.current = Phaser.Math.RND.between(this.speed.min, this.speed.max);
    this.anims.timeScale = this.speed.current / 200;
    this.body.velocity.x = this.speed.current;

    /** Animation: play walking */
    this.play('walk');
  }

  handleHit() {
    // this.scene.audio_hit.play();
  }

  preUpdate(time: number, delta: number) {
    super.preUpdate(time, delta);

    if (this.active) {
      if (this.x <= this.patrolPath.min) {
        this.body.velocity.x = this.speed.current;
      } else if (this.x >= this.patrolPath.max) {
        this.body.velocity.x = -this.speed.current;
      }
    }
  }
}