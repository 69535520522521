import Setup from "@/helpers/Setup";
import Phaser from "phaser";
import CircularProgress from 'phaser3-rex-plugins/plugins/circularprogress';
import Scaling from "../configs/scaling";
import { ITeams } from "../interfaces/ITeams";

export default class Load extends Phaser.Scene {
  logo!: Phaser.GameObjects.Image;
  loader!: CircularProgress;

  constructor() {
    super({
      key: "load"
    });
  }

  preload() {
    /** Logo */
    this.logo = this.add.image(this.cameras.main.width / 2, (this.cameras.main.height / 2) - (Scaling.getPixelbyDPR(60)), 'campaign_logo');

    /** Loader */
    this.loader = new CircularProgress(this, {
      x: this.cameras.main.centerX, y: this.cameras.main.height - Scaling.getPixelbyDPR(80),
      radius: Scaling.getPixelbyDPR(25),
      trackColor: 0x000000,
      barColor: 0xFFFFFF,
      thickness: 0.1,
      valuechangeCallback: () => { }
    });
    this.add.existing(this.loader);

    /** Loader: Events */
    this.load.on("progress", (value: number) => {
      this.loader.setValue(value);
    });

    this.load.on("complete", () => {
      this.loader.setAlpha(0);

      this.time.delayedCall(500, () => {
        this.setupAnimations(() => {
          this.scene.stop()
          this.game.events.emit("loaded");
        });
      })
    });

    /** STADIUM */
    this.load.image('scoreboard', Scaling.imagePath('scoreboard', 'png'));
    this.load.spritesheet('grandstand', Scaling.imagePath('sprite-grandstand', 'png'), { frameWidth: Scaling.getPixelbyDPR(375), frameHeight: Scaling.getPixelbyDPR(202) });
    this.load.image('emitter_score', Scaling.imagePath('sprite-target-particles', 'png'))
    
    /** PLAYFIELD */
    this.load.image('goal', Scaling.imagePath('sprite-field-target', 'png'));
    this.load.image('ball', Scaling.imagePath('sprite-shot', 'png'));
    this.load.image('lines_goal', Scaling.imagePath('sprite-field-target-markers', 'png'));
    this.load.image('lines_kickoff', Scaling.imagePath('sprite-field-meters', 'png'));
    this.load.spritesheet('field_light', Scaling.imagePath('spritesheet-field-light', 'png'), { frameWidth: Scaling.getPixelbyDPR(56), frameHeight: Scaling.getPixelbyDPR(56) });
    this.load.spritesheet('field_dark', Scaling.imagePath('spritesheet-field-dark', 'png'), { frameWidth: Scaling.getPixelbyDPR(56), frameHeight: Scaling.getPixelbyDPR(56) });

    /** HUD */
    this.load.image('pause_button', Scaling.imagePath('btn-pause', 'png'));
    this.load.image('play_button', Scaling.imagePath('btn-play', 'png'));
    this.load.spritesheet('rating_star', Scaling.imagePath('spritesheet-rating', 'png'), { frameWidth: Scaling.getPixelbyDPR(16), frameHeight: Scaling.getPixelbyDPR(16) });

    /** DIALOG */
    this.load.image('dialog_background_introduction', Scaling.imagePath('sprite-background-introduction', 'png'));
    this.load.image('dialog_background_end_match', Scaling.imagePath('sprite-background-dialog', 'png'));
    this.load.spritesheet('dialog_player_end_match_won', Scaling.imagePath('spritesheet-end-match-player-won', 'png'), { frameWidth: Scaling.getPixelbyDPR(120), frameHeight: Scaling.getPixelbyDPR(160) });
    this.load.spritesheet('dialog_player_end_match_lost', Scaling.imagePath('spritesheet-end-match-player-lost', 'png'), { frameWidth: Scaling.getPixelbyDPR(120), frameHeight: Scaling.getPixelbyDPR(160) });
    this.load.spritesheet('dialog_player_end_match_tie', Scaling.imagePath('spritesheet-end-match-player-tie', 'png'), { frameWidth: Scaling.getPixelbyDPR(120), frameHeight: Scaling.getPixelbyDPR(160) });
    
    /** PLAYER TEAM */
    this.load.spritesheet('player', Scaling.imagePath(`sprite-player-single-${Setup.getValue('settings.game.player.team').value}`, 'png'), { frameWidth: Scaling.getPixelbyDPR(30), frameHeight: Scaling.getPixelbyDPR(56) });
    
    /** ENEMY TEAMS */
    this.load.spritesheet('goalkeeper', Scaling.imagePath('sprite-keeper', 'png'), { frameWidth: Scaling.getPixelbyDPR(40), frameHeight: Scaling.getPixelbyDPR(56) });
    const teamsJSON = JSON.parse(Setup.getValue('settings.game.teams').value) as ITeams;
    for(const team of teamsJSON.teams){
      this.load.spritesheet(`team_front_single_${team.id}`, Scaling.imagePath(`sprite-enemy-single-${team.id}`, 'png'), { frameWidth: Scaling.getPixelbyDPR(30), frameHeight: Scaling.getPixelbyDPR(56) });
      this.load.spritesheet(`team_front_double_${team.id}`, Scaling.imagePath(`sprite-enemy-double-${team.id}`, 'png'), { frameWidth: Scaling.getPixelbyDPR(60), frameHeight: Scaling.getPixelbyDPR(56) });
      this.load.image(`team_logo_${team.id}`, Scaling.imagePath(`sprite-teamlogo-${team.id}`, 'png'));
    }

    /** AUDIO */
    this.load.audio('theme', ['sounds/games/freekick/background.mp3']);
    this.load.audio('hit_goal', ['sounds/games/freekick/goal.mp3']);
    this.load.audio('hit_stand', ['sounds/games/freekick/hit_stand.mp3']);
    this.load.audio('hit_player', ['sounds/games/freekick/hit_player.mp3']);
    this.load.audio('hit_pole', ['sounds/games/freekick/hit_pole.mp3']);
    this.load.audio('shoot', ['sounds/games/freekick/shoot.mp3']);
    this.load.audio('cheer', ['sounds/games/freekick/cheer.mp3']);

  }

  setupAnimations(returnFunction: () => void) {
    this.tweens.add({
      targets: this.logo,
      alpha: 0,
      duration: 500,
      ease: 'ease',
      onComplete: () => returnFunction()
    })
  }
}


