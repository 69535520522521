import Phaser from "phaser";
import Scaling from "../../configs/scaling";

interface IRatingSettings {
  maxRating: number;
}

export default class Rating extends Phaser.GameObjects.Container {
  maxRating: number;

  constructor(scene: Phaser.Scene, x: number, y: number, settings: IRatingSettings) {
    super(scene, x, y);
    this.scene.add.existing(this);

    /** Settings */
    this.maxRating = settings.maxRating || 5;

    /** Stars */
    for (let index = 0; index < this.maxRating; index++) {
      const star = this.scene.add.sprite(0, 0, 'rating_star');
      star.setOrigin(0, 0.5);
      star.setX((star.width + Scaling.getPixelbyDPR(4)) * index);
      star.setFrame(2);

      this.add(star);
    }

    /** Position: Reposition to center */
    this.setX(this.x - (this.getBounds().width / 2));
  }

  setRating(amount: number) {
    let index = 1;

    this.iterate((star: Phaser.GameObjects.Sprite) => {
      let frame = 0;
      if (amount < index) {
        amount === (index - 0.5) ? frame = 1 : frame = 2;
      }

      this.scene.time.addEvent({
        delay: index * 100, callback: () => {
          star.setFrame(frame);
          this.scene.tweens.add({
            targets: star,
            scale: { from: 1, to: 1.1 },
            duration: 200,
            ease: 'Cubic.InOut',
            yoyo: true
          })
        }
      });
      index++;
    })
  }

  reset() {
    this.iterate((star: Phaser.GameObjects.Sprite) => {
      star.setFrame(2);
    })
  }
}